<template>
  <v-container fluid grid-list-xl class="p-0">
    <v-layout wrap>
      <!--If already on subscription-->
      <template v-if="hasSubscription">
        <!--If not managed-->
        <v-flex
          v-if="!user.business.agency"
          xs12
          sm6
          class="flex-card"
          height="100%"
        >
          <v-card class="grow">
            <v-card-title>
              <div>
                <div>Subscription</div>
                <div
                  v-if="
                    standardSubscription &&
                      standardSubscription.ends_at === null
                  "
                  class="text&#45;&#45;secondary text-subtitle-1"
                >
                  Subscription is active
                </div>
                <span
                  v-else-if="
                    standardSubscription &&
                      standardSubscription.ended_at === null
                  "
                  class="text&#45;&#45;secondary"
                  >Subscription is active</span
                >
                <span
                  v-else-if="
                    standardSubscription &&
                      standardSubscription.ends_at !== null
                  "
                  class="subtitle-2 text--secondary"
                >
                  Subscription expires:
                  {{ standardSubscription.ends_at | humanize_datetime }}
                </span>
              </div>
            </v-card-title>
            <v-card-text>
              Active Subscription
              <br />
              Current plan: <strong>{{ standardSubscription.product.display_name }}</strong>
            </v-card-text>
            <v-card-actions
              v-if="standardSubscription && standardSubscription !== null && user.active_subscription.stripe_id !== 'shopify'"
            >
              <v-spacer></v-spacer>
              <v-btn
                v-if="
                  standardSubscription
                  && standardSubscription.ends_at === null
                "
                text
                color="primary"
                @click="cancelDialog = true"
                >Cancel subscription</v-btn
              >

              <v-dialog v-model="cancelDialog" max-width="550px">
                <v-card>
                  <v-card-title>
                    <div class="headline">Cancel subscription</div>
                  </v-card-title>
                  <v-card-text>

                    <v-alert
                      type="warning"
                      class="black--text"
                      v-if="standardSubscription.has_scheduled_change"
                    >
                      Your subscription is associated to a custom agreement, which means you can't cancel it yourself.
                      Please send us an email <a href="mailto:support@makeinfluence.com">support@makeinfluence.com</a> and we'll resolve it together!
                    </v-alert>

                    <p>
                      All your active campaign(s) will have their end date(s) set to the end date of your subscription. 
                    </p>

                    <v-select
                      v-model="cancelReason"
                      :items="cancelReasonsReverse"
                      :disabled="standardSubscription.has_scheduled_change"
                      label="What is the reason for your cancellation?"
                    ></v-select>

                    <v-textarea
                      label="Tell us what we can do better 😊"
                      :disabled="standardSubscription.has_scheduled_change"
                      v-model="cancelReasonText"
                      :counter="500"
                    />
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="cancelDialog = false"
                      >I'll stay
                    </v-btn>

                    <v-btn
                      text
                      :loading="cancelLoading"
                      :disabled="
                        (cancelReason === 'other' &&
                          cancelReasonText.length < 3) ||
                          !cancelReason
                      "
                      @click="stopSubscription()"
                      >Stop subscription
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-btn
                v-if="
                  standardSubscription && standardSubscription.ends_at !== null
                "
                color="success"
                :loading="resumeLoading"
                @click="resumeSubscription()"
              >
                Resume subscription
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </template>

      <v-flex xs12 sm6 class="flex-card" height="100%">
        <v-card class="grow">
          <v-card-title>
            <div>
              <div>Payment card</div>
              <i class="body-2 text--secondary">
                <template v-if="user.card_brand">
                  Current: {{ user.card_brand }} **** **** ****
                  {{ user.card_last_four }}
                </template>
                <template v-else>
                  No payment card associated
                </template>
              </i>
            </div>
          </v-card-title>
          <v-card-text class="fade-enter">
            Card text
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :text="!!user.card_brand"
              :color="user.card_brand ? 'primary' : 'success'"
              @click="showCardDialog = true"
            >
              <template v-if="user.card_brand">
                Change payment card
              </template>
              <template v-else>
                Add payment card
              </template>
            </v-btn>
          </v-card-actions>
        </v-card>

        <stripe-card-dialog v-model="showCardDialog"></stripe-card-dialog>
      </v-flex>

      <v-flex xs12>
        <v-card>
          <v-card-title>
            <div>
              <div>Invoice details</div>
              <span class="body-2 light-grey"
                >Choose the information to be included on all future invoices</span
              >
            </div>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-xl class="p-0">
              <v-layout v-if="invoiceAccount" row>
                <v-flex xs12 sm6>
                  <v-text-field
                    label="Email"
                    v-model="invoiceAccount.email"
                    name="email"
                    v-validate="'required|email'"
                    :error-messages="validation.email ? validation.email : errors.collect('email')"
                    @keyup="validation.email = null"
                  />

                  <v-text-field
                    label="Company"
                    v-model="invoiceAccount.name"
                    name="name"
                    v-validate="'required'"
                    :error-messages="validation.name ? validation.name : errors.collect('name')"
                    @keyup="validation.name = null"
                  />

                  <v-text-field
                    label="VAT"
                    v-model="vat"
                    :hint="
                      invoiceAccount.vat.verification
                        ? invoiceAccount.vat.verification
                        : ''
                    "
                    persistent-hint
                    name="VAT"
                    v-validate="'required'"
                    :error-messages="validation.vat ? validation.vat : errors.collect('vat')"
                    @keyup="validation.vat = null"
                  />
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    label="Country"
                    disabled
                    v-model="invoiceAccount.country"
                    name="country"
                  />

                  <v-text-field
                    label="Address"
                    v-model="invoiceAccount.address"
                    name="address"
                    v-validate="'required'"
                    :error-messages="validation.address ? validation.address : errors.collect('address')"
                    @keyup="validation.address = null"
                  />

                  <v-text-field
                    label="Postal code"
                    v-model="invoiceAccount.postal_code"
                    name="postal_code"
                    v-validate="'required'"
                    :error-messages="validation.postal_code ? validation.postal_code : errors.collect('postal_code')"
                    @keyup="validation.postal_code = null"
                  />

                  <v-text-field
                    label="City"
                    v-model="invoiceAccount.city"
                    name="city"
                    v-validate="'required'"
                    :error-messages="validation.city ? validation.city : errors.collect('city')"
                    @keyup="validation.city = null"
                  />
                </v-flex>
              </v-layout>
              <v-layout v-else row>
                <v-flex sm6 xs12>
                  <v-skeleton-loader
                    type="list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
                  ></v-skeleton-loader>
                </v-flex>
                <v-flex sm6 xs12>
                  <v-skeleton-loader
                    type="list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
                  ></v-skeleton-loader>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              color="primary"
              @click="saveInvoiceAccount()"
              :loading="invoiceAccountLoading"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>

      <v-flex xs12 v-if="user.stripe_id">
        <invoice-table-card></invoice-table-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import humanize_datetime from "@/helpers/filters/humanize_datetime";
import InvoiceTableCard from "../cards/InvoiceTableCard";
import StripeCardDialog from "../../dialogs/StripeCardDialog";

export default {
  components: { StripeCardDialog, InvoiceTableCard },
  filters: { humanize_datetime },
  data: () => ({
    cancelDialog: false,
    cancelLoading: false,
    cancelReasons: [
      { value: "lacking_time", text: "Does not have time for it" },
      {
        value: "pausing_influencer_marketing",
        text: "Pausing influencer marketing"
      },
      {
        value: "influencers_not_active",
        text: "Influencers not active"
      },
      {
        value: "trouble_finding_influencers",
        text: "Trouble finding the right influencers"
      },
      {
        value: "our_product_doesnt_fit",
        text: "Our product doesn't fit your influencers"
      },
      {
        value: "platform_hard_to_understand",
        text: "The platform is hard to navigate"
      },
      {
        value: "expected_more_service",
        text: "Expected more service"
      },
      {
        value: "too_many_products",
        text: "Had to send too many products to the influencers"
      },
      {
        value: "not_enough_conversions",
        text: "Not enough conversions"
      }
    ],
    resumeLoading: false,
    cancelReason: "",
    cancelReasonText: "",
    showCardDialog: false,
    invoiceAccount: null,
    vat: "",
    invoiceAccountLoading: false,
    validation: {
      email: null,
      name: null,
      vat: null,
      address: null,
      postal_code: null,
      city: null
    }
  }),
  computed: {
    ...mapState("core/auth", ["user"]),
    ...mapGetters("subscriptions", [
      "getActiveSubscription",
      "hasSubscription"
    ]),
    cancelReasonsReverse() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      let reasons = this.cancelReasons.reverse();
      reasons.push({ value: "other", text: "Other" });
      return reasons;
    },
    standardSubscription() {
      return this.getActiveSubscription;
    },
    userActiveSubscription() {
      if (this.hasSubscription) {
        return this.user.active_subscription;
      } else {
        return null;
      }
    }
  },
  methods: {
    ...mapActions("core/auth", ["getUserFromApi"]),
    ...mapActions("subscriptions", ["setSubscription"]),
    ...mapActions("core", [
      "getInvoiceAccount",
      "updateInvoiceAccount",
      "cancelSubscription",
      "updateSelfSubscription"
    ]),
    stopSubscription() {
      this.cancelLoading = true;

      const params = {
        cancel_reason: this.cancelReason,
        cancel_reason_text: this.cancelReasonText
      };
      this.cancelSubscription(params).then(
        response => {
          if (response.error_code) {
            this.setSnackError("Something went wrong");
            this.cancelLoading = false;
          } else {
            this.cancelDialog = false;
            this.cancelLoading = false;
            this.cancelReason = null;
            this.cancelReasonText = "";
            this.setSnackSuccess("Subscription cancelled");
            this.setSubscription(response);
          }
        },
        error => {
          console.log(error);
          this.cancelLoading = false;
          this.setSnackError("Something went wrong");
        }
      );
    },
    resumeSubscription() {
      this.resumeLoading = true;
      const params = {
        resume: true,
        product: "standard"
      };
      this.updateSelfSubscription(params).then(
        updateSelfSubscription => {
          this.resumeLoading = false;
          this.setSnackSuccess("Subscription resumed");
          const selfSubscription = {
            ...updateSelfSubscription.data[0],
            ends_at: null
          };
          this.setSubscription(selfSubscription);
        },
        () => {
          this.resumeLoading = false;
          this.setSnackError("Something went wrong");
        }
      );
    },
    saveInvoiceAccount() {
      this.invoiceAccountLoading = true;
      let params = this.cloneDeep(this.invoiceAccount);
      params.vat = this.vat;
      for (let key in params) {
        if (!params[key]) {
          delete params[key];
        }
      }
      this.updateInvoiceAccount(params).then(
        response => {
          this.setSnackSuccess("Saved");
          this.invoiceAccountLoading = false;
          this.invoiceAccount = response;
          this.vat = response.vat.value;
          // delete unused stuff
          delete this.invoiceAccount.__typename;
        },
        errors => {
          const arr = this.$errorHandler.getValidationErrors(errors);
          this.validation = {
            email: this.$errorHandler.getErrorMessage(arr, "email"),
            name: this.$errorHandler.getErrorMessage(arr, "name"),
            vat: this.$errorHandler.getErrorMessage(arr, "vat"),
            address: this.$errorHandler.getErrorMessage(arr, "address"),
            postal_code: this.$errorHandler.getErrorMessage(arr, "postal_code"),
            city: this.$errorHandler.getErrorMessage(arr, "city")
          };
          this.setSnackError("Error");
          this.invoiceAccountLoading = false;
        }
      );
    }
  },
  created() {
    this.getInvoiceAccount().then(
      response => {
        this.invoiceAccount = response;
        this.vat = this.invoiceAccount.vat.value;
        // delete unused stuff
        delete this.invoiceAccount.__typename;
      },
      error => {
        this.setSnackError("Error");
        console.log(error);
      }
    );
  }
};
</script>
